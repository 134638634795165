export interface ICompanyEntity {
    id: number;
    name: string;
    idn: string;
    is_group: boolean;
    companies: ICompanyChild[],
}

export interface IListedCompanyEntity {
    id: number;
    name: string;
    idn: string;
    group_name?: string;
    group_id?: number;
    group_idn?: string;
    is_group?: boolean;
}

export interface ICompanyChild {
    id: number;
    name: string;
    idn: string,
}

export interface CompanyCreatorResponse {
    company_id: number;
    company_name: string;
}

export function mapToCompanyFilter(model: CompanyCreatorResponse): ICompanyChild {
    return {
        id: model.company_id,
        name: model.company_name,
        idn: "",
    }
}
