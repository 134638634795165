import {UploadsRepository} from "common/repositories";
import {AsyncJob} from "common/stores/job";
import {IUploadedFileEntity} from "common/types";
import {makeAutoObservable} from "mobx";
import {UserRepository} from "../../repositories";
import {IUserEntity} from "../../types";
import {IProfileForm} from "./IProfileForm";

export const AVATAR_MIME_TYPES = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/webp",
].join(",");

const MAX_AVATAR_SIZE_MB = 3;

export class EditProfilePopupStore {
    submitFormFunc: (() => void) | null;
    closePopupFunc: ((firstName: string, surName: string, avatar: string) => void) | null;

    avatar: string;
    initialValues: IProfileForm;
    isSaved: boolean;

    private newAvatar: File | null;
    private newAvatarUrl: string | null;
    private changeAvatarError: string | null;
    private fetchProfileJob: AsyncJob<typeof EditProfilePopupStore.prototype.fetchProfile>;
    private saveProfileJob: AsyncJob<typeof EditProfilePopupStore.prototype.saveProfile>;

    constructor(private readonly usersRepo: UserRepository,
                private readonly uploadsRepo: UploadsRepository) {
        this.initialValues = {
            firstName: "",
            surName: "",
            middleName: "",
            password: "",
            passwordConfirm: "",
        };

        this.isSaved = false;
        this.closePopupFunc = null;

        this.avatar = "";
        this.newAvatar = null;
        this.newAvatarUrl = null;
        this.changeAvatarError = null;

        this.submitFormFunc = null;

        makeAutoObservable(this, {}, {autoBind: true});

        this.fetchProfileJob = new AsyncJob({job: this.fetchProfile});
        this.saveProfileJob = new AsyncJob({job: this.saveProfile});
    }

    get isLoading() {
        return this.fetchProfileJob.isPending || this.saveProfileJob.isPending;
    }

    get isGlobalError() {
        return this.fetchProfileJob.isRejected;
    }

    get error() {
        if (this.saveProfileJob.isRejected) {
            return "Ошибка при сохранении";
        }

        return this.changeAvatarError;
    }

    onMount() {
        this.fetchProfileJob.start();
    }

    changeAvatar(file: File) {
        this.changeAvatarError = null;
        if (file.size > MAX_AVATAR_SIZE_MB * 1024 * 1024) {
            this.changeAvatarError = `Размер файла не должен превышать ${MAX_AVATAR_SIZE_MB} Мб`;
            return;
        }

        this.newAvatar = file;
        this.newAvatarUrl = null;
        this.avatar = URL.createObjectURL(file);
    }

    setClosePopupFunc(func: (firstName: string, surName: string, avatar: string) => void) {
        this.closePopupFunc = func;
    }

    setSubmitFormFunc(func: () => void) {
        this.submitFormFunc = func;
    }

    async submitForm() {
        this.submitFormFunc?.();
    }

    async save(values: IProfileForm) {
        this.saveProfileJob.start(values);
    }

    onSubmit(user: IProfileForm) {
        this.saveProfileJob.start(user);
    }

    private* fetchProfile(signal: AbortSignal) {
        const user: IUserEntity = yield this.usersRepo.findByToken(signal);
        const [surName, firstName, middleName] = user.name.split(" ", 3);
        this.initialValues = {
            surName,
            firstName: firstName || "",
            middleName: middleName || "",
            password: "",
            passwordConfirm: "",
        };
        this.avatar = user.avatar_url;
    }

    private* saveProfile(_: AbortSignal, user: IProfileForm) {
        if (this.newAvatar && !this.newAvatarUrl) {
            const uploaded: IUploadedFileEntity = yield this.uploadsRepo.upload(this.newAvatar);
            this.newAvatarUrl = uploaded.link;
        }

        yield this.usersRepo.updateProfile({
            fname: user.firstName,
            mname: user.middleName,
            sname: user.surName,
            password: user.password || undefined,
            avatar: this.newAvatarUrl || undefined,
        });

        this.closePopupFunc?.(user.firstName, user.surName, this.avatar);
        this.isSaved = true;
    }
}
