import {useEffect, useRef} from "react";

function useGlobalEvent<K extends keyof DocumentEventMap>(
    eventName: K,
    callback: (e: DocumentEventMap[K]) => void,
    enabled: boolean = true,
    capturing: boolean = false,
) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const eventListener = (e: any) => {
            callbackRef.current(e);
        };
        document.addEventListener(eventName, eventListener, capturing);

        return () => document.removeEventListener(eventName, eventListener, capturing);
    }, [eventName, capturing, enabled]);
}

export default useGlobalEvent;