import {HouseSystem} from "houses/types";
import {
    CommentEvent,
    mapToRelatedTask,
    mapToTaskEvent,
    mapToTaskStatus,
    RelatedTask,
    RelatedTaskResponse,
    TaskEventResponse,
    TaskStatus,
    TaskStatusResponse
} from "./index";

export const TaskConstraints = {
    MAX_CONTACT_INFO_LENGTH: 50,
    MAX_TASK_DESCRIPTION_LENGTH: 1500,
};

export interface Task {
    id: number;
    type: string;
    number: number;
    entrance: number;
    floor: number;
    apartment: number;
    gate: number;
    companyName: string;
    creatorName: string;
    system: HouseSystem;

    /**
     * Принимает значение 0, когда заявка делегирована на другую компанию, но исполнитель еще не назначен.
     */
    executorId: number | null;
    executorName: string;
    executorAvatar: string;
    ownerAvatar: string;
    creationDate: Date;
    description: string;
    status: TaskStatus;
    phone: string;
    delegatedTaskNumber: number | null;
    companyId: number;
    contactName: string;
    planningDate: Date | null;
    workCompletionDate: Date | null;
    payedDate: Date | null;
    orderedDate: Date | null;
    receivedDate: Date | null;
    isPayed: boolean;
    isOrdered: boolean;
    isReceived: boolean;
    changes: number;
    houseId: number;
    address: string;
    lastComment: CommentEvent | null;
    highlight: "danger" | "warning" | null;
    submissionBy: "cabinet" | "service_mobile" | "citizen";
    hierarchyPosition: "root" | "descendant" | null;
    canEdit: boolean;
    related: RelatedTask[];
    finalDescription: string;

    /**
     * Статус делегации данной заявки:
     * - <i>from</i> - заявка делегирована из другой компании
     * - <i>to</i> - заявка делегирована в данную компанию
     */
    delegationStatus: "to" | "from" | null;
}

export interface UpdateTaskParams {
    systemId?: number;
    executorId?: number;
    companyId?: number;
    typeId?: number;
    apartment?: string;
    payedDate?: Date;
    orderedDate?: Date;
    receivedDate?: Date;
    planningDate?: Date | null;
    contactPhone?: string;
    contactInfo?: string;
    finalDescription?: string;
}

export interface TaskResponse {
    id: number;
    task_num: number;
    entrance: number;
    floor: number;
    apartment: number;
    gate: number;
    company_name: string;
    owner_avatar: string;
    house_id: number;
    address: string;
    owner_name: string;
    sensor_name: string;
    full_system: HouseSystem;
    executor_id: number;
    executor_avatar: string;
    delegated_task_number: number | null;
    user_name: string;
    creation_date: number;
    description: string;
    phone: string;
    contact_citizen_info: string;
    payed: number;
    ordered: number;
    received: number;
    planning_date: number;
    task_type: string;
    payed_date: number;
    ordered_date: number;
    received_date: number;
    company_id: number;
    changes: number;
    highlight: "danger" | "warning" | null;
    status: TaskStatusResponse;
    last_comment: TaskEventResponse | null;
    submitted_by: "cabinet" | "service_mobile" | "citizen";
    can_edit: boolean;
    work_completion_date: number;
    related_tasks: RelatedTaskResponse[];
    delegation_status: "from" | "to" | null;
    hierarchy_position: "root" | "descendant" | null;
    final_description: string | null;
}

export interface FullTaskResponse extends TaskResponse {
    related_tasks: RelatedTaskResponse[];
}

export function mapToTask(entity: TaskResponse): Task {
    return {
        id: entity.id,
        type: entity.task_type,
        number: entity.task_num,
        entrance: entity.entrance,
        floor: entity.floor,
        apartment: entity.apartment,
        gate: entity.gate,
        companyName: entity.company_name,
        companyId: entity.company_id,
        houseId: entity.house_id,
        address: entity.address,
        creatorName: entity.owner_name,
        system: entity.full_system,
        executorId: entity.executor_id,
        executorName: entity.user_name,
        executorAvatar: entity.executor_avatar,
        ownerAvatar: entity.owner_avatar,
        creationDate: new Date(entity.creation_date * 1000),
        description: entity.description,
        status: mapToTaskStatus(entity.status),
        delegatedTaskNumber: entity.delegated_task_number || null,
        phone: entity.phone,
        orderedDate: entity.ordered_date === 0 ? null : new Date(entity.ordered_date * 1000),
        payedDate: entity.payed_date === 0 ? null : new Date(entity.payed_date * 1000),
        receivedDate: entity.received_date === 0 ? null : new Date(entity.received_date * 1000),
        changes: entity.changes,
        contactName: entity.contact_citizen_info,
        planningDate: entity.planning_date === 0 ? null : new Date(entity.planning_date * 1000),
        isOrdered: entity.payed === 1,
        isReceived: entity.received === 1,
        isPayed: entity.payed === 1,
        lastComment: entity.last_comment ? mapToTaskEvent(entity.last_comment) as CommentEvent : null,
        highlight: entity.highlight,
        submissionBy: entity.submitted_by,
        canEdit: entity.can_edit,
        hierarchyPosition: entity.hierarchy_position,
        delegationStatus: entity.delegation_status,
        workCompletionDate: entity.work_completion_date ? new Date(entity.work_completion_date * 1000) : null,
        related: entity.related_tasks ? entity.related_tasks.map(mapToRelatedTask) : [],
        finalDescription: entity.final_description || "",
    };
}

export type CreateTaskParam = {
    entrance: number;
    floor: number;
    apartment: number;
    gate: number;
    system: number;
    executor_id: number;
    description: string;
    contact_citizen_info: string;
    received_date: string;
    payed_date: string;
    ordered_date: string;
    task_type: string;
    company_id?: number;
    house_id: number;
    phone: string;
};

export type GetTasksParam = {
    company_id: number | null;
    page: number;
    limit: number;
    order_by: string;
    filter_by: Record<any, string>;
};

export type CountTasksParam = {
    signal: AbortSignal;
    company_id: number | null;
    filter_by: Record<any, string>;
};

export type TaskItemInfoType = "house" | "user" | "status" | "type" | "company" | "system";