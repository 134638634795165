import {DumpStateRepository} from "common/repositories";
import {DumperStore} from "common/stores";
import {makeAutoObservable} from "mobx";
import {AsyncJob} from "../../../common/stores/job";

type Params = {
    companyId: number | null;
    dumpRepo: DumpStateRepository,
    provideTotalCount: (signal: AbortSignal) => Promise<number>,
    providePageIds: () => number[],
    provideSelectedFilters: () => object,
}

export class TaskDumperStore {
    isActivated: boolean;
    base: DumperStore;
    count: number;
    private readonly provideTotalCount: Params["provideTotalCount"];
    private readonly fetchCountJob: AsyncJob<typeof TaskDumperStore.prototype._fetchCount>;

    constructor(params: Params) {
        this.count = 0;
        const dumpRepo = params.dumpRepo;
        const companyId = params.companyId;
        this.base = new DumperStore({
            providePageIds: params.providePageIds,
            fetchDump: (mode, ids) => dumpRepo.create({
                companyId,
                tasks: ids,
                mode: mode,
                filterBy: params.provideSelectedFilters(),
            }),
            fetchCancelDump: () => dumpRepo.delete(companyId),
            fetchDumpState: () => dumpRepo.find(companyId),
        })

        this.isActivated = false;
        this.provideTotalCount = params.provideTotalCount;
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchCountJob = new AsyncJob({job: this._fetchCount});
    }

    get isLoading() {
        return this.fetchCountJob.isPending;
    }

    get modalError() {
        return this.base.modalError || this.fetchCountJob.errorMessage;
    }

    get selectedCount() {
        return this.base.countSelected(this.count);
    }

    get canSelectAll() {
        return this.base.canSelectAll;
    }

    get totalCount() {
        return this.count;
    }

    get canSelect() {
        return this.isActivated && this.base.pendingDump === null;
    }

    toggleSelectionMode() {
        if (!this.isActivated) {
            this.fetchCountJob.start();
        } else {
            this.base.reset();
            this.count = 0;
            this.isActivated = false;
        }
    }

    downloadDump() {
        this.isActivated = false;
        this.base.downloadDump();
    }

    dismissDump() {
        this.isActivated = false;
        this.base.dismissDump();
    }

    reset() {
        this.base.reset();
    }

    clearError() {
        this.base.clearError();
        this.fetchCountJob.clearError();
        this.base.clearError();
    }

    private* _fetchCount(signal: AbortSignal) {
        this.count = yield this.provideTotalCount(signal);
        this.isActivated = true;
    }
}
