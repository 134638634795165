import {SearchFieldsStoreBuilder} from "common/components/search_bar/with_fields";
import {SearchFieldRepository, SortOrderRepository} from "common/repositories";
import {EntryFilters, EntryFiltersBuilder, SingleEntryFilter} from "common/stores/filters";
import {CompanyRepository} from "companies/repositories";
import {ICompanyChild, ListedCompany} from "companies/types";
import {HouseSystemRepository, NewHouseRepository} from "houses/repositories";
import {ListedHouse, ListedHouseSystem} from "houses/types";
import {makeAutoObservable} from "mobx";
import {TaskProgramRepository, TaskRepository, TaskStatusRepository, TaskTypeRepository} from "tasks/repositories";
import {TaskFilter, TaskFilterValue, TaskStatus, TaskType} from "tasks/types";
import {NewUserRepository} from "users/repositories";
import {ListedUser} from "users/types";
import IconBiArrows24x24 from "../../../../common/components/icons/IconBiArrows24x24";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {BooleanFilterStore} from "./BooleanFilterStore";
import {DateRangeFilterStore} from "./DateRangeFilterStore";
import {EquipmentDateFilterStore} from "./EquipmentDateFilterStore";
import {RelatedTaskFilterStore} from "./RelatedTaskFilterStore";
import {SearchFilterStore} from "./SearchFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";
import {TextFilterStore} from "./TextFilterStore";
import {TriStateFilterStore} from "./TriStateFilterStore";
import {ValueFiltersFactory, ValueFilterStore} from "./ValueFilterStore";
import {CategoryFilterFactory, CategoryFilterStore} from "./CategoryFilterStore";
import {GroupFilterFactory, GroupFilterStore} from "./GroupFilterStore";

export class TaskFiltersStore {
    readonly base: EntryFilters<object>;
    readonly search: SearchFilterStore;

    readonly creationRange: DateRangeFilterStore;
    readonly workRange: DateRangeFilterStore;
    readonly completionRange: DateRangeFilterStore;
    readonly payedRange: DateRangeFilterStore;
    readonly orderedRange: DateRangeFilterStore;
    readonly receivedRange: DateRangeFilterStore;

    readonly root: BooleanFilterStore;
    readonly descendants: BooleanFilterStore;
    readonly relatedTask: RelatedTaskFilterStore;

    readonly gate: TextFilterStore;
    readonly entrance: TextFilterStore;
    readonly floor: TextFilterStore;
    readonly apartment: TextFilterStore;

    readonly address: ValueFilterStore<ListedHouse>;
    readonly creatorGroup: GroupFilterStore;
    readonly creator: CategoryFilterStore<ListedUser, "citizen" | "etd_user">;
    readonly creatorCompany: ValueFilterStore<ICompanyChild>;
    readonly commentAuthor: ValueFilterStore<ListedUser>;
    readonly historyAuthor: ValueFilterStore<ListedUser>;
    readonly executorGroup: GroupFilterStore;
    readonly executor: ValueFilterStore<ListedUser>;
    readonly executorCompany: ValueFilterStore<ICompanyChild>;
    readonly status: ValueFilterStore<TaskStatus>;
    readonly type: ValueFilterStore<TaskType>;
    readonly company: ValueFilterStore<ListedCompany>;
    readonly system: ValueFilterStore<ListedHouseSystem>;

    readonly payedDate: EquipmentDateFilterStore;
    readonly orderedDate: EquipmentDateFilterStore;
    readonly receivedDate: EquipmentDateFilterStore;

    readonly phoneExistence: TriStateFilterStore;
    readonly contactInfoExistence: TriStateFilterStore;
    readonly documentsExistence: TriStateFilterStore;
    readonly changesExistence: TriStateFilterStore;

    private visibleFilters: TaskFilter[];
    private allFilters: TaskFilter[];
    private readonly filters: BaseTaskFilterStore[];
    private readonly order: SingleEntryFilter<any, any>

    constructor(
        userCompanyId: number,
        companyId: number | null,
        search: string,
        serialized: string,
        fieldsBuilder: SearchFieldsStoreBuilder,
        fieldsRepo: SearchFieldRepository,
        taskRepo: TaskRepository,
        userRepo: NewUserRepository,
        statusRepo: TaskStatusRepository,
        typeRepo: TaskTypeRepository,
        companyRepo: CompanyRepository,
        systemRepo: HouseSystemRepository,
        houseRepo: NewHouseRepository,
        programRepo: TaskProgramRepository,
        orderRepo: SortOrderRepository,
    ) {
        this.visibleFilters = [];
        this.allFilters = [];
        this.search = new SearchFilterStore(fieldsBuilder, fieldsRepo, () => this.refreshFilters());
        this.root = new BooleanFilterStore({key: "root", title: "Родительские"});
        this.descendants = new BooleanFilterStore({key: "descendants", title: "Дочерние"});
        this.relatedTask = new RelatedTaskFilterStore(companyId || userCompanyId, programRepo);

        this.creationRange = new DateRangeFilterStore({
            startKey: "creation_date_from",
            endKey: "creation_date_to",
            title: "Создана",
            isPassedDatesOnly: true,
        });
        this.workRange = new DateRangeFilterStore({
            startKey: "work_completion_date_from",
            endKey: "work_completion_date_to",
            title: "Завершена",
            isPassedDatesOnly: true,
        });
        this.completionRange = new DateRangeFilterStore({
            startKey: "completion_date_from",
            endKey: "completion_date_to",
            title: "Планируется",
        });
        this.payedRange = new DateRangeFilterStore({
            startKey: "payed_date_from",
            endKey: "payed_date_to",
            title: "Дата оплаты",
        });
        this.orderedRange = new DateRangeFilterStore({
            startKey: "ordered_date_from",
            endKey: "ordered_date_to",
            title: "Дата заказа",
        });
        this.receivedRange = new DateRangeFilterStore({
            startKey: "received_date_from",
            endKey: "received_date_to",
            title: "Дата поступления",
        });

        this.gate = new TextFilterStore({key: "gate", title: "Калитка"});
        this.entrance = new TextFilterStore({key: "entrance", title: "Парадная"});
        this.floor = new TextFilterStore({key: "floor", title: "Этаж"});
        this.apartment = new TextFilterStore({key: "apartment", title: "Квартира"});

        this.address = ValueFiltersFactory.createAddressFilter(taskRepo, houseRepo, companyId);
        this.creator = CategoryFilterFactory.createCreatorFilter(taskRepo, userRepo, companyId);
        this.creatorCompany = ValueFiltersFactory.createCreatorCompanyFilter(taskRepo, companyRepo, companyId);
        this.creatorGroup = GroupFilterFactory.createCreatorFilter(this.creator, this.creatorCompany);
        this.commentAuthor = ValueFiltersFactory.createCommentAuthorFilter(taskRepo, userRepo, companyId || userCompanyId);
        this.historyAuthor = ValueFiltersFactory.createHistoryAuthorFilter(taskRepo, userRepo, companyId || userCompanyId);
        this.executor = ValueFiltersFactory.createExecutorFilter(taskRepo, userRepo, companyId);
        this.executorCompany = ValueFiltersFactory.createExecutorCompaniesFilter(taskRepo, companyRepo, companyId);
        this.executorGroup = GroupFilterFactory.createExecutorFilter(this.executor, this.executorCompany);
        this.status = ValueFiltersFactory.createStatusFilter(taskRepo, statusRepo);
        this.type = ValueFiltersFactory.createTypeFilter(taskRepo, typeRepo);
        this.company = ValueFiltersFactory.createCompanyFilter(taskRepo, companyRepo, companyId);
        this.system = ValueFiltersFactory.createSystemFilter(taskRepo, systemRepo, companyId);

        this.payedDate = new EquipmentDateFilterStore({key: "payed", title: "Оплачено"});
        this.orderedDate = new EquipmentDateFilterStore({key: "ordered", title: "Заказано"});
        this.receivedDate = new EquipmentDateFilterStore({key: "received", title: "Получено"});

        this.phoneExistence = new TriStateFilterStore({key: "phone", title: "Телефон"});
        this.contactInfoExistence = new TriStateFilterStore({key: "contact_info", title: "Контактное лицо"});
        this.documentsExistence = new TriStateFilterStore({key: "documents", title: "Вложения"});
        this.changesExistence = new TriStateFilterStore({key: "changes", title: "Наличие изменений"});

        this.base = new EntryFilters();
        const allFilters = new EntryFiltersBuilder<object>()
            .addSingleValueAsync({
                icon: <IconBiArrows24x24 fill="currentColor"/>,
                type: "orderBy",
                title: "Сортировка",
                async fetchValues() {
                    const response = await orderRepo.findAllForTasks();
                    return [
                        {
                            key: "not_selected",
                            label: "Не выбрано",
                            value: null,
                        },
                        ...response.map(item => ({
                            key: item.id.toString(),
                            label: item.name,
                            value: item.id,
                        })),
                    ];
                },
                apply: selected => selected !== null ? {order_by: selected} : {},
            })
            .build();
        this.base.setFilters(allFilters);
        this.order = this.base.getByTypeSafe("orderBy") as SingleEntryFilter<any, any>;
        this.order.setChangeListener(() => this.refreshFilters());

        this.filters = [
            this.search,
            this.root,
            this.descendants,
            this.relatedTask,
            this.creationRange,
            this.workRange,
            this.completionRange,
            this.payedRange,
            this.orderedRange,
            this.receivedRange,
            this.gate,
            this.entrance,
            this.floor,
            this.apartment,
            this.address,
            this.creatorGroup,
            this.commentAuthor,
            this.historyAuthor,
            this.executorGroup,
            this.status,
            this.type,
            this.company,
            this.system,
            this.payedDate,
            this.orderedDate,
            this.receivedDate,
            this.phoneExistence,
            this.contactInfoExistence,
            this.documentsExistence,
            this.changesExistence,
        ];
        this.deserializeString(serialized);
        if (search.trim()) {
            this.search.setSearch(search);
            this.search.applySearch();
        }
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get applied(): any {
        const result: Record<any, string> = {};
        for (const filter of this.allFilters) {
            result[filter.key] = filter.value.map(item => item.value).join(",");
        }

        return result;
    }

    get serialized(): string | null {
        const result = JSON.stringify(this.allFilters);
        return result === "[]" ? null : result;
    }

    get appliedFilters() {
        return this.visibleFilters;
    }

    get appliedAllFilters() {
        return this.allFilters;
    }

    get hasActive() {
        return this.filters.some(filter => filter.isActive)
            || this.order.selected !== null;
    }

    get canClear() {
        return this.hasActive || this.search.searchInternal.trim().length > 0;
    }

    resetAll() {
        for (const filter of this.filters) {
            filter.reset();
        }

        this.order.setSelected(null);
        this.search.reset();
    }

    get realtimeFilters() {
        const visible = new SerializedFilterBuilderStore();
        const all = new SerializedFilterBuilderStore();

        for (const filter of this.filters) {
            if (filter.isAlwaysApply || filter.isActive) {
                //TODO: remove isVisible
                if (filter.isVisible) {
                    filter.apply(visible, true);
                    filter.apply(all, false);
                } else {
                    filter.apply(all, false);
                }
            }
        }

        const selectedOrder = this.order.selected;
        if (selectedOrder) {
            visible.upsert("order_by", selectedOrder.label.toString(), selectedOrder.key.toString());
            all.upsert("order_by", selectedOrder.label.toString(), selectedOrder.key.toString());
        }

        return {visible: visible.filters, all: all.filters};
    }

    resetAllImmediately() {
        this.resetAll();
        this.visibleFilters = [];
        this.allFilters = [];
    }

    deserialize(filters: TaskFilter[]) {
        this.deserializeInternal(new SerializedFilterBuilderStore(filters));
    }

    removeFilter(key: string, value: TaskFilterValue) {
        const serialized = this.appliedAllFilters;
        const builder = new SerializedFilterBuilderStore(JSON.parse(JSON.stringify(serialized)));
        builder.removeValue(key, value);

        const filterOwner = this.filters.find(filter => filter.isOwnFilter(key));
        if (filterOwner) {
            filterOwner.onFilterRemove?.(key, builder);
            filterOwner.deserialize(builder);
            this.refreshFilters();
        }
    }

    refreshFilters() {
        const filters = this.realtimeFilters;
        if (JSON.stringify(this.allFilters) !== JSON.stringify(filters.all)) {
            this.visibleFilters = filters.visible;
            this.allFilters = filters.all;
        }
    }

    private deserializeString(serialized: string) {
        if (serialized === "") {
            return;
        }

        try {
            const deserialized = JSON.parse(serialized);
            const builder = new SerializedFilterBuilderStore(deserialized);
            this.deserializeInternal(builder);
            this.refreshFilters();
        } catch (e) {
            console.error("Unable to deserialize task list filters", e);
            this.resetAll();
        }
    }

    private deserializeInternal(builder: SerializedFilterBuilderStore) {
        for (const filter of this.filters) {
            filter.deserialize(builder);
        }

        const selectedOrder = builder.find("order_by");
        if (selectedOrder) {
            this.order.setSelected({
                key: selectedOrder.value,
                value: selectedOrder.value,
                label: selectedOrder.title,
            });
        } else {
            this.order.setSelected(null);
        }
    }
}