import {HistoryStackProvider, PopupStackContext, usePopupStackContext} from "common/hooks";
import {observer} from "mobx-react-lite";
import React, {useMemo, useState} from "react";
import {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import {RouterProvider} from "react-router-dom";
import GlobalLoadingScreen from "./GlobalLoadingScreen";
import {RepositoriesStore, RepositoriesStoreContext} from "./RepositoriesStore";
import {etdRouter} from "./routes";
import {UnderConstruction} from "./UnderConstruction";

function App() {
    const [isUnderConstruction, setUnderConstruction] = useState(false);
    const repos = useMemo(() => new RepositoriesStore(() => {
        setUnderConstruction(true);
    }), []);
    const modalHost = usePopupStackContext();

    if (isUnderConstruction) {
        return <UnderConstruction/>;
    }

    return (
        <PopupStackContext.Provider value={modalHost}>
            <SkeletonTheme baseColor="var(--background-skeleton)" highlightColor="var(--background-skeleton-anim)">
                <HistoryStackProvider>
                    <RepositoriesStoreContext.Provider value={repos}>
                        <GlobalLoadingScreen>
                            <RouterProvider router={etdRouter}/>
                        </GlobalLoadingScreen>
                    </RepositoriesStoreContext.Provider>
                </HistoryStackProvider>
            </SkeletonTheme>
        </PopupStackContext.Provider>
    );
}

export default observer(App);