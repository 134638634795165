import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {makeAutoObservable} from "mobx";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";
import {ValueFilterStore} from "./ValueFilterStore";
import {ListedUser} from "../../../../users/types";
import {ICompanyChild} from "../../../../companies/types";
import {CategoryFilterStore} from "./CategoryFilterStore";
import {TriState} from "../../../../common/components/checkbox";

interface FilterOptions {
    filters: BaseTaskFilterStore[];
    isOwnFilter: (key: string) => boolean;
    apply: (builder: SerializedFilterBuilderStore, isVisible: boolean) => void;
    deserialize: (builder: SerializedFilterBuilderStore) => void;
    onFilterRemove: (key: string, builder: SerializedFilterBuilderStore) => void;
}

export class GroupFilterStore implements BaseTaskFilterStore {
    readonly isVisible = true;
    readonly isAlwaysApply = true;

    constructor(private readonly options: FilterOptions) {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.options.filters.some(c => c.isActive);
    }

    apply(builder: SerializedFilterBuilderStore, isVisible: boolean) {
        for (const filter of this.options.filters) {
            filter.apply(builder, isVisible);
        }
        this.options.apply(builder, isVisible);
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        for (const filter of this.options.filters) {
            filter.deserialize(builder);
        }
        this.options.deserialize(builder);
    }

    onFilterRemove(key: string, builder: SerializedFilterBuilderStore) {
        this.options.onFilterRemove(key, builder);
    }

    isOwnFilter(key: string) {
        return this.options.isOwnFilter(key) || this.options.filters.some(c => c.isOwnFilter(key));
    }

    reset() {
        for (const filter of this.options.filters) {
            filter.reset();
        }
    }
}

export class GroupFilterFactory {
    static createCreatorFilter(
        creator: CategoryFilterStore<ListedUser, "etd_user" | "citizen">,
        creatorCompany: ValueFilterStore<ICompanyChild>,
    ) {
        return new GroupFilterStore({
            filters: [creator, creatorCompany],
            apply(builder, isVisible) {
                if (creator.state !== TriState.Unchecked) {
                    if (creatorCompany.state !== TriState.Unchecked) {
                        if (!isVisible) {
                            builder.upsert("creator_type", "", "any");
                        }
                    } else {
                        if (creator.state === TriState.Indeterminate) {
                            if (!isVisible) {
                                builder.upsert("creator_type", "", "user");
                            }
                        } else {
                            if (creator.selected.length > 0) {
                                if (!isVisible) {
                                    builder.upsert("creator_type", "", "user");
                                }
                            } else {
                                builder.upsert(
                                    "creator_type",
                                    `Создатель: ${creator.selectedCategory?.title?.toLowerCase() || ""}`, "user",
                                );
                            }
                        }
                    }
                } else {
                    if (creatorCompany.state === TriState.Indeterminate) {
                        if (!isVisible) {
                            builder.upsert("creator_type", "", "company");
                        }
                    } else if (creatorCompany.state === TriState.Checked) {
                        if (creatorCompany.selected.length > 0) {
                            if (!isVisible) {
                                builder.upsert("creator_type", "", "company");
                            }
                        } else {
                            builder.upsert("creator_type", "Создатель: компании", "company");
                        }
                    }
                }
            },
            deserialize(builder) {
                const creatorType = builder.findValue("creator_type");
                if (creatorType === "company" || creatorType === "any") {
                    if (creatorCompany.state === TriState.Unchecked) {
                        creatorCompany.setState(TriState.Checked);
                    }
                } else if (creatorType === "user") {
                    creatorCompany.reset();
                } else {
                    creatorCompany.reset();
                    creator.reset();
                }
            },
            isOwnFilter(key) {
                return key === "creator_type";
            },
            onFilterRemove(key, builder) {
                if (key === creatorCompany.options.excludeKey) {
                    const values = builder.findMany(creatorCompany.options.excludeKey);
                    if (!values?.length) {
                        builder.remove("creator_type");
                    }
                } else if (key === creator.options.excludeKey) {
                    const values = builder.findMany(creator.options.excludeKey);
                    if (!values?.length) {
                        builder.remove("creator_type");
                    }
                }
            }
        });
    }

    static createExecutorFilter(
        executor: ValueFilterStore<ListedUser>,
        executorCompany: ValueFilterStore<ICompanyChild>,
    ) {
        return new GroupFilterStore({
            filters: [executor, executorCompany],
            apply(builder, isVisible) {
                if (executor.state !== TriState.Unchecked) {
                    if (executorCompany.state !== TriState.Unchecked) {
                        if (!isVisible) {
                            builder.upsert("executor_type", "", "any");
                        }
                    } else {
                        if (executor.state === TriState.Indeterminate) {
                            if (!isVisible) {
                                builder.upsert("executor_type", "", "user");
                            }
                        } else {
                            if (executor.selected.length > 0) {
                                if (!isVisible) {
                                    builder.upsert("executor_type", "", "user");
                                }
                            } else {
                                builder.upsert(
                                    "executor_type",
                                    `Исполнитель: пользователи`, "user",
                                );
                            }
                        }
                    }
                } else {
                    if (executorCompany.state === TriState.Indeterminate) {
                        if (!isVisible) {
                            builder.upsert("executor_type", "", "company");
                        }
                    } else if (executorCompany.state === TriState.Checked) {
                        if (executorCompany.selected.length > 0) {
                            if (!isVisible) {
                                builder.upsert("executor_type", "", "company");
                            }
                        } else {
                            builder.upsert("executor_type", "Исполнитель: компании", "company");
                        }
                    }
                }
            },
            deserialize(builder) {
                const creatorType = builder.findValue("executor_type");
                if (creatorType === "company" || creatorType === "any") {
                    if (executorCompany.state === TriState.Unchecked) {
                        executorCompany.setState(TriState.Checked);
                    }
                } else if (creatorType === "user") {
                    executorCompany.reset();
                } else {
                    executorCompany.reset();
                    executor.reset();
                }
            },
            isOwnFilter(key) {
                return key === "executor_type";
            },
            onFilterRemove(key, builder) {
                if (key === executorCompany.options.excludeKey) {
                    const values = builder.findMany(executorCompany.options.excludeKey);
                    if (!values?.length) {
                        builder.remove("executor_type");
                    }
                } else if (key === executor.options.excludeKey) {
                    const values = builder.findMany(executor.options.excludeKey);
                    if (!values?.length) {
                        builder.remove("executor_type");
                    }
                }
            }
        });
    }
}