import {ICompanyChild} from "companies/types";

export interface User {
    id: number;
    fullName: string;
    avatarUrl: string;
    position: string;
    companyId: number;
    companyName: string;
    roleId: number;
    roleName: string;
    email: string;
    phone: string;
    isInCompanyGroup: boolean;

    /**
     * Содержит список компаний, к которым имеет доступ пользователь, если его роль <i>Диспетчер ЕТД</i>. Для других ролей
     * всегда пустой массив.
     */
    dispatcherCompanies: ICompanyChild[];
}

/**
 * Минимальная информация о пользователе. Полный объект - {@link User}.
 *
 * Используется для оптимизации поиска по пользователям на страницах заявок, благодаря отсутствию время затратных полей для формирования
 * на сервере.
 */
export interface ListedUser {
    id: number;

    fullName: string;

    avatarUrl: string;

    companyName: string;

    position: string;

    dispatcherCompanies: ICompanyChild[];
}

export interface UserUpsertParams {
    firstName: string;
    secondName: string;
    middleName: string;
    phone: string;
    email: string;
    roleId: number;
    position: string;
    password: string;

    /**
     * Список идентификаторов компаний, к которым пользователь имеет доступ.
     *
     * Можно задать только пользователям с ролью <i>Диспетчер ЕТД</i>. Отправка данного поля доступна только <i>Администраторам ЕТД</i>.
     */
    dispatcherCompanies?: number[],
}

export interface UserResponse {
    id: number;
    full_name: string;
    avatar: string;
    position: string;
    company_id: number;
    company_name: string;
    role_id: number;
    role_name: string;
    email: string;
    phone: string;
    dispatcher_companies: ICompanyChild[],
    is_user_in_cg: boolean,
}

export interface ListedUserResponse {
    id: number;

    first_name: string;

    second_name: string;

    middle_name: string;

    avatar: string;

    company_name: string;

    position: string;

    dispatcher_companies: ICompanyChild[];
}

export function mapToUser(entity: UserResponse): User {
    return {
        id: entity.id,
        fullName: entity.full_name,
        avatarUrl: entity.avatar,
        position: entity.position,
        companyId: entity.company_id,
        companyName: entity.company_name,
        email: entity.email,
        phone: entity.phone,
        roleId: entity.role_id,
        roleName: entity.role_name,
        dispatcherCompanies: entity.dispatcher_companies,
        isInCompanyGroup: entity.is_user_in_cg,
    };
}

export type UpdateProfileParam = {
    fname: string;
    mname: string;
    sname: string;
    password?: string;
    avatar?: string;
};

export type CreateUserParam = {
    name: string;
    position: string;
    role_id: number;
    company_id: number | undefined;
    email: string;
    phone: string;
    systems: { object_id: number, system_id: number }[];
    password?: string;
};

export type FindAllUsersParam = {
    page: number,
    limit?: number,
    search?: string,
    companies?: number[],
    signal?: AbortSignal;

    /**
     * Определяет нужно ли выполнить поиск по пользователям из дочерних компаний.
     */
    isIncludeChildCompanies?: boolean;

    /**
     * Исключает из выдачи всех пользователей, которые имеют доступ к указанному дому.
     */
    excludeWithHouseAccess?: number;
};

export type FindAllPossibleUsersParam = {
    houseId: number,
    page: number,
    limit?: number,
    search?: string,
};

export type FindAllListedUsersParam = {
    page: number,
    limit?: number,
    search?: string,
    companyId: number | null,
    signal?: AbortSignal,
};

export type FindAllCreatorUsersParam = {
    page: number,
    limit?: number,
    search?: string,
    type?: "any" | "citizen" | "etd_user" | null,
    companyId: number | null,
    signal?: AbortSignal,
};

export type FindAllReportExecutorsParam = {
    page: number,
    limit?: number,
    search?: string,
    companyId: number | null,
    periodFrom: Date,
    periodTo: Date,
    signal?: AbortSignal,
};

export function mapToListedUser(entity: ListedUserResponse): ListedUser {
    return {
        id: entity.id,
        fullName: `${entity.second_name} ${entity.first_name} ${entity.middle_name}`.trim(),
        avatarUrl: entity.avatar,
        companyName: entity.company_name,
        position: entity.position,
        dispatcherCompanies: entity.dispatcher_companies,
    };
}
