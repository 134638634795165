export const CompanyConstraints = {
    nameMinLength: 3,
    nameMaxLength: 40,
    bankNameMaxLength: 40,
    ogrnMaxLength: 13,
    kppLength: 9,
    idnLength: 10,
    legalEntityNameMaxLength: 40,
    billingQrMaxLength: 256,
};

export interface ChildCompany {
    id: number;
    name: string
}

export interface ListedCompany {
    id: number;
    name: string;
}

export interface Company {
    id: number;
    name: string;
    isGroup: boolean;
    companies: ChildCompany[];

    legalEntityName: string;
    idn: string;
    kpp: string;
    bankName: string;
    ogrn: string;
    billingQr: CompanyBillingQr[];
    pass: CompanyPass[];
}

export interface CompanyBillingQr {
    companyId: number;
    url: string;
}

export interface CompanyPass {
    companyId: number;
    stampUrl: string | null;
    signatureUrl: string | null;
    logoUrl: string | null;
}

export interface UpdateCompanyParams {
    name: string;
    legalEntityName: string;
    idn: string;
    kpp: string;
    bankName: string;
    ito: string;
}

export interface UpdateCompanyBillingQrParams {
    url: string;
}

export interface UpdateCompanyPassParams {
    stampUrl: string | null;
    signatureUrl: string | null;
    logoUrl: string | null;
}

export interface CompanyResponse {
    id: number;
    name: string;
    is_group: boolean;
    companies: ChildCompany[];

    legal_entity: string | null;
    idn: string | null;
    kpp: string | null;
    bank_name: string | null;
    ogrn: string | null;
    billing_qr: CompanyBillingQrResponse[];
    entry_pass: CompanyPassResponse[];
}

export interface CompanyBillingQrResponse {
    company_id: number;
    url: string;
}

export interface CompanyPassResponse {
    company_id: number;
    stamp_url: string | null;
    signature_url: string | null;
    logo_url: string | null;
}

export type CreateCompanyParam = {
    name: string;
    idn: string;
    servers?: number[];
}

export type CreateCompanyGroupParam = {
    name: string | undefined;
    ids: { id: number }[]
};

export interface FindAllListedCompanyParam {
    page: number;
    limit?: number;
    search?: string;
    companyId?: number | null;
    signal?: AbortSignal;
}

export interface FindAllCompanyCreatorsParam {
    page: number;
    limit?: number;
    search?: string;
    companyId?: number | null;
    signal?: AbortSignal;
}

export function mapToCompany(response: CompanyResponse): Company {
    return {
        id: response.id,
        name: response.name,
        isGroup: response.is_group,
        companies: response.companies,
        legalEntityName: response.legal_entity || "",
        idn: response.idn || "",
        kpp: response.kpp || "",
        bankName: response.bank_name || "",
        ogrn: response.ogrn || "",
        billingQr: response.billing_qr.map(item => ({
            companyId: item.company_id,
            url: item.url
        })),
        pass: response.entry_pass.map(item => ({
            companyId: item.company_id,
            stampUrl: item.stamp_url,
            signatureUrl: item.signature_url,
            logoUrl: item.logo_url
        })),
    };
}
