import {TriState} from "common/components/checkbox";
import {makeAutoObservable} from "mobx";
import {TaskProgramRepository} from "tasks/repositories";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";
import {ValueFilterItem} from "./ValueFilterStore";

export class RelatedTaskFilterStore implements BaseTaskFilterStore {
    state: TriState;
    selected: ValueFilterItem[];
    number: string;
    readonly isVisible = true;

    constructor(
        private readonly companyId: number,
        private readonly programRepo: TaskProgramRepository,
    ) {
        this.state = TriState.Unchecked;
        this.selected = [];
        this.number = "";
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.state !== TriState.Unchecked;
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
            this.selected = [];
            this.number = "";
        } else {
            this.state = TriState.Checked;
        }
    }

    setSelected(values: ValueFilterItem[]) {
        this.selected = values;
    }

    setNumber(value: string) {
        this.number = value;
    }

    async searchPrograms(search: string, signal: AbortSignal) {
        return (await this.programRepo.findAll(this.companyId, signal))
            .filter(program => program.name.toLowerCase().includes(search.toLowerCase()))
    }

    isOwnFilter(key: string) {
        return key === "related_tasks" || key === "related_task_program" || key === "related_task_number";
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.state === TriState.Indeterminate) {
            builder.upsert("related_tasks", "Связанные заявки", "false");
            return;
        }

        if (this.selected.length > 0) {
            const values = this.selected.map(item => ({
                title: `Программа ${item.title}`,
                value: item.value
            }));

            builder.upsertMany("related_task_program", values);
        }

        const number = this.number.trim();
        if (number.length) {
            builder.upsert("related_task_number", `Номер ${number}`, number);
        }

        if (!number.length && !this.selected.length) {
            builder.upsert("related_tasks", "Связанные заявки", "true");
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const relatedTasksValue = builder.findValue("related_tasks");
        if (relatedTasksValue === "false") {
            this.state = TriState.Indeterminate;
            this.selected = [];
            this.number = "";
        } else if (relatedTasksValue === "true") {
            this.state = TriState.Checked;
            this.selected = [];
            this.number = "";
        } else {
            const selected = builder.findMany("related_task_program")?.map(item => {
                // Фильтры сохраняются в формате "Программа <название>".
                // В InputBox нужно отображать только название, поэтому убираем приписку, если она есть
                let title = "";
                if (item.title.startsWith("Программа ")) {
                    title = item.title.slice("Программа ".length);
                }

                return {
                    title,
                    value: item.value
                };
            });

            if (selected?.length) {
                this.state = TriState.Checked;
                this.selected = selected || [];
                this.number = builder.findValue("related_task_number") || "";
            } else {
                this.state = TriState.Unchecked;
                this.selected = [];
                this.number = "";
            }
        }
    }

    reset() {
        this.state = TriState.Unchecked;
        this.selected = [];
        this.number = "";
    }
}