import {TriState} from "common/components/checkbox";
import {Pageable} from "common/types";
import {formatName} from "common/utils";
import {CompanyRepository} from "companies/repositories";
import {HouseSystemRepository, NewHouseRepository} from "houses/repositories";
import {makeAutoObservable} from "mobx";
import {TaskRepository, TaskStatusRepository, TaskTypeRepository} from "tasks/repositories";
import {TaskFilterValue} from "tasks/types";
import {NewUserRepository} from "users/repositories";
import {ListedUser} from "users/types";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";
import {InputBoxItem} from "../../../../common/components/input/box";
import {AsyncJob} from "../../../../common/stores/job";

export interface ValueFilterItem {
    title: string;
    value: string;
}

export interface SearchedValueFilter<T> {
    original: T;
    mapped: ValueFilterItem;
}

interface FilterOptions<T> {
    key: string;
    excludeKey?: string;
    existenceKey?: string;
    useExclusionOnly?: boolean;
    isActiveWhenChecked?: boolean;
    useExclusionOnlyInvisible?: boolean;
    isIncludeEmpty?: boolean;
    title: string;
    shouldAddTitle?: boolean;
    mapValue: (value: T) => ValueFilterItem;
    onSearch: (value: string, page: number, signal: AbortSignal) => Promise<T[] | Pageable<T>>;
    onFetchSelected: (selected: string[], signal: AbortSignal) => Promise<T[]>;
}

export class ValueFilterStore<T> implements BaseTaskFilterStore {
    state: TriState;
    selected: ValueFilterItem[];
    isLoading: boolean;
    private selectedItems: InputBoxItem<SearchedValueFilter<T>>[];
    readonly isVisible = true;
    private readonly fetchSelectedJob: AsyncJob<typeof ValueFilterStore.prototype._fetchSelected>;

    constructor(readonly options: FilterOptions<T>) {
        this.selected = [];
        this.selectedItems = [];
        this.state = TriState.Unchecked;
        this.isLoading = false;
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchSelectedJob = new AsyncJob({job: this._fetchSelected});
    }

    get error() {
        return this.fetchSelectedJob.errorMessage;
    }

    get selectedMenuItems(): InputBoxItem<SearchedValueFilter<T>>[] {
        if (this.isLoading) {
            return this.selected.map(item => ({
                id: item.value,
                label: item.title,
                value: null as any as SearchedValueFilter<T>,
            }));
        }

        return this.selectedItems;
    }

    get isActive() {
        if (this.options.existenceKey) {
            return this.state === TriState.Checked || this.state === TriState.Indeterminate;
        }

        return this.state !== TriState.Unchecked && (this.options.isActiveWhenChecked || this.selected.length > 0);
    }

    get title() {
        return this.options.title;
    }

    static mapDeserialized(baseTitle: string, value: TaskFilterValue[]): ValueFilterItem[] {
        return value.map(item => {
            // Фильтры сохраняются в формате "Создатель: <имя>".
            // В InputBox нужно отображать только имя, поэтому убираем приписку, если она есть
            let title = item.title;
            if (item.title.startsWith(baseTitle)) {
                const split = item.title.split(": ", 2);
                title = split[1];
            }
            return {
                title,
                value: item.value
            };
        });
    }

    fetchSelected() {
        this.fetchSelectedJob.clearError();
        if (this.isLoading && !this.fetchSelectedJob.isPending) {
            this.fetchSelectedJob.start();
        }
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
            this.selected = [];
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
        } else {
            this.state = TriState.Checked;
        }
    }

    setSelected(values: InputBoxItem<SearchedValueFilter<T>>[]) {
        this.selectedItems = values;
        this.selected = values.map(v => ({
            title: v.label,
            value: v.id.toString(),
        }));
    }

    async searchItems(search: string, page: number, signal: AbortSignal): Promise<{
        canLoadMore: boolean,
        items: SearchedValueFilter<T>[]
    }> {
        const result = await this.options.onSearch(search, page, signal);
        if (Array.isArray(result)) {
            return {
                canLoadMore: false,
                items: result.map(item => ({
                    original: item,
                    mapped: this.options.mapValue(item),
                })),
            };
        } else {
            return {
                canLoadMore: result.maxPage > page + 1,
                items: result.items.map(item => ({
                    original: item,
                    mapped: this.options.mapValue(item),
                })),
            };
        }
    }

    isOwnFilter(key: string) {
        return this.options.key === key || this.options.existenceKey === key || this.options.excludeKey === key;
    }

    setState(state: TriState) {
        this.state = state;
    }

    apply(builder: SerializedFilterBuilderStore, isVisible: boolean) {
        if (this.selected.length > 0 || this.options.isIncludeEmpty) {
            const values = this.selected.map(item => ({
                title: this.options.shouldAddTitle ? `${this.options.title}: ${item.title}` : item.title,
                value: item.value
            }));

            if (this.options.excludeKey && this.state === TriState.Indeterminate) {
                builder.upsertMany(this.options.excludeKey, values);
            } else {
                builder.upsertMany(this.options.key, values);
            }

            return;
        }

        if ((this.options.useExclusionOnly || this.options.useExclusionOnlyInvisible)
            && this.options.excludeKey && this.state === TriState.Indeterminate) {

            if (this.options.useExclusionOnlyInvisible) {
                if (!isVisible) {
                    builder.upsertMany(this.options.excludeKey, [])
                }
            } else {
                builder.upsert(this.options.excludeKey, this.options.title, "")
            }

            return;
        }

        if (this.options.existenceKey) {
            builder.upsert(
                this.options.existenceKey,
                this.options.title,
                this.state === TriState.Checked ? "true" : "false",
            );
        }
    }

    reset() {
        this.state = TriState.Unchecked;
        this.changeSelected([]);
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        if (this.options.excludeKey && builder.has(this.options.excludeKey)) {
            this.changeSelected(ValueFilterStore.mapDeserialized(this.title, builder.findMany(this.options.excludeKey) || []));
            this.state = TriState.Indeterminate;
            return;
        }

        if (this.options.existenceKey && builder.has(this.options.existenceKey)) {
            this.state = builder.findValue(this.options.existenceKey) === "true"
                ? TriState.Checked
                : TriState.Unchecked;
            return;
        }

        const selected = builder.findMany(this.options.key);
        if (selected) {
            this.changeSelected(ValueFilterStore.mapDeserialized(this.title, selected));
            this.state = TriState.Checked;
            return;
        }

        this.reset();
    }

    private changeSelected(selected: ValueFilterItem[]) {
        if (selected.length === 0) {
            this.selected = [];
            this.selectedItems = [];
            this.isLoading = false;
            return;
        }

        // noinspection DuplicatedCode
        if (this.selected.length !== selected.length) {
            this.selected = selected;
            this.isLoading = true;
        } else {
            const isSelectedChanged = selected.some((it, index) => {
                return it.value !== this.selected[index].value;
            });
            this.selected = selected;
            this.isLoading = isSelectedChanged;
        }
    }

    // noinspection DuplicatedCode
    private* _fetchSelected(signal: AbortSignal) {
        try {
            const values: T[] = yield this.options.onFetchSelected(this.selected.map(it => it.value), signal);
            const mapped = values.map(it => {
                const mapped = this.options.mapValue(it);
                return {
                    id: mapped.value,
                    label: mapped.title,
                    value: {
                        mapped,
                        original: it,
                    },
                };
            });

            const order = new Map(this.selected.map(({value}, index) => [value, index]));
            this.selectedItems = mapped.sort((x, y) => {
                return (order.get(x.id) ?? Number.MAX_SAFE_INTEGER) - (order.get(y.id) ?? Number.MAX_SAFE_INTEGER);
            });
        } finally {
            this.isLoading = false;
        }
    }
}

export class ValueFiltersFactory {
    static createAddressFilter(
        taskRepo: TaskRepository,
        houseRepo: NewHouseRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "houses",
            excludeKey: "exclude_houses",
            useExclusionOnly: true,
            title: "Адрес",
            onSearch(search, page, signal) {
                return houseRepo.findAllListed({
                    search,
                    companyId,
                    page,
                    signal,
                    limit: 25,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("house", selected, signal);
            },
            mapValue: item => ({
                title: item.address,
                value: item.id.toString(),
            }),
        });
    }

    static createCreatorCompanyFilter(
        taskRepo: TaskRepository,
        companyRepo: CompanyRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "companies_creators",
            excludeKey: "exclude_companies_creators",
            title: "Компании",
            onSearch(search, page, signal) {
                return companyRepo.findAllTaskCreators({
                    search,
                    page,
                    signal,
                    limit: 25,
                    companyId: companyId,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("company", selected, signal);
            },
            mapValue: company => ({
                title: company.name,
                value: company.id.toString(),
            }),
            isActiveWhenChecked: true,
            useExclusionOnlyInvisible: true,
            isIncludeEmpty: true,
            shouldAddTitle: true,
        });
    }

    static createCommentAuthorFilter(
        taskRepo: TaskRepository,
        userRepo: NewUserRepository,
        companyId: number,
    ) {
        return new ValueFilterStore({
            key: "comment_authors",
            excludeKey: "exclude_comment_authors",
            existenceKey: "comments",
            title: "Комментарий",
            onSearch(search, page, signal) {
                return userRepo.findAllListedCommentAuthors({
                    search,
                    companyId,
                    page,
                    signal,
                    limit: 25,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("user", selected, signal);
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createHistoryAuthorFilter(
        taskRepo: TaskRepository,
        userRepo: NewUserRepository,
        companyId: number,
    ) {
        return new ValueFilterStore({
            key: "history_authors",
            excludeKey: "exclude_history_authors",
            title: "История",
            onSearch(search, page, signal) {
                return userRepo.findAllListedHistoryAuthors({
                    search,
                    companyId,
                    page,
                    signal,
                    limit: 25,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("user", selected, signal);
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
        });
    }

    static createExecutorFilter(
        taskRepo: TaskRepository,
        userRepo: NewUserRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "executors",
            excludeKey: "exclude_executors",
            useExclusionOnly: true,
            title: "Сотрудники",
            onSearch(search, page, signal) {
                return userRepo.findAllListedExecutors({
                    search,
                    companyId,
                    page,
                    signal,
                    limit: 25,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("user", selected, signal);
            },
            mapValue: this.mapUserValue,
            shouldAddTitle: true,
            isActiveWhenChecked: true,
            useExclusionOnlyInvisible: true,
            isIncludeEmpty: true,
        });
    }

    static createExecutorCompaniesFilter(
        taskRepo: TaskRepository,
        companyRepo: CompanyRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "companies_executors",
            excludeKey: "exclude_companies_executors",
            useExclusionOnly: true,
            title: "Компании",
            onSearch(search, page, signal) {
                return companyRepo.findAllTaskExecutors({
                    search,
                    companyId,
                    page,
                    signal,
                    limit: 25,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("company", selected, signal);
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
            shouldAddTitle: true,
            isActiveWhenChecked: true,
            useExclusionOnlyInvisible: true,
            isIncludeEmpty: true,
        });
    }

    static createStatusFilter(
        taskRepo: TaskRepository,
        statusRepo: TaskStatusRepository,
    ) {
        return new ValueFilterStore({
            key: "statuses",
            excludeKey: "exclude_statuses",
            useExclusionOnly: true,
            title: "Статус",
            async onSearch(search, _, signal) {
                return (await statusRepo.findAll(signal))
                    .filter(item => item.localizedName.toLowerCase().includes(search.toLowerCase()));
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("status", selected, signal);
            },
            mapValue: item => ({
                title: item.localizedName,
                value: item.id.toString(),
            }),
        });
    }

    static createTypeFilter(
        taskRepo: TaskRepository,
        typeRepo: TaskTypeRepository,
    ) {
        return new ValueFilterStore({
            key: "types",
            excludeKey: "exclude_types",
            useExclusionOnly: true,
            title: "Тип",
            async onSearch(search, _, signal) {
                return (await typeRepo.findAll(signal))
                    .filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("type", selected, signal);
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    static createCompanyFilter(
        taskRepo: TaskRepository,
        companyRepo: CompanyRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "companies",
            excludeKey: "exclude_companies",
            useExclusionOnly: true,
            title: "Компания",
            onSearch(search, page, signal) {
                return companyRepo.findAllListed({
                    search,
                    page,
                    signal,
                    companyId: companyId,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("company", selected, signal);
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    static createSystemFilter(
        taskRepo: TaskRepository,
        systemRepo: HouseSystemRepository,
        companyId: number | null,
    ) {
        return new ValueFilterStore({
            key: "systems",
            excludeKey: "exclude_systems",
            useExclusionOnly: true,
            title: "Система",
            onSearch(search, page, signal) {
                return systemRepo.findAllListed({
                    search,
                    page,
                    signal,
                    limit: 25,
                    companyId: companyId,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("system", selected, signal);
            },
            mapValue: item => ({
                title: item.name,
                value: item.id.toString(),
            }),
        });
    }

    private static mapUserValue(user: ListedUser): ValueFilterItem {
        return {
            title: formatName(user.fullName),
            value: user.id.toString(),
        };
    }
}