import {TaskFilter, TaskFilterValue} from "tasks/types";

export interface TaskFilterUiState {
    key: string;
    value: TaskFilterValue[];
}

export class SerializedFilterBuilderStore {
    constructor(public filters: TaskFilterUiState[] = []) {
    }

    upsert(key: string, title: string, value: string) {
        const filter = this.findOrCreate(key);
        filter.value = [{title, value}];
    }

    upsertMany(key: string, values: TaskFilterValue[]) {
        const filter = this.findOrCreate(key);
        filter.value = values;
    }

    find(key: string): TaskFilterValue | null {
        return this.filters.find(f => f.key === key)?.value[0] || null;
    }

    findValue(key: string): string | null {
        return this.filters.find(f => f.key === key)?.value[0].value || null;
    }

    findMany(key: string): TaskFilterValue[] | null {
        return this.filters.find(f => f.key === key)?.value || null;
    }

    findManyValues(key: string): string[] | null {
        return this.filters
            .find(f => f.key === key)?.value
            ?.map(v => v.value) || null;
    }

    has(key: string): boolean {
        return !!this.filters.find(f => f.key === key);
    }

    removeValue(key: string, value: TaskFilterValue) {
        const filter = this.filters.find(f => f.key === key);
        if (!filter) {
            return;
        }

        filter.value = filter.value.filter(v => v.value !== value.value);
        if (filter.value.length === 0) {
            this.filters = this.filters.filter(f => f.key !== key);
        }
    }

    remove(key: string) {
        this.filters = this.filters.filter(f => f.key !== key);
    }

    private findOrCreate(key: string): TaskFilter {
        const found = this.filters.find(f => f.key === key);
        if (found) {
            return found;
        }

        const newFilter: TaskFilter = {
            key,
            value: [],
        };
        this.filters.push(newFilter);
        return newFilter;
    }
}