import {AuthRepository} from "users/repositories";
import {SessionStore} from "users/stores";
import {AuthToken} from "users/types";
import {ServicesConfig} from "../config";
import {RefreshTokenError, ServerError} from "./ServerError";

type HttpClientRequest = {
    body?: object | FormData | undefined;
    params?: HttpQueryParams;
    signal?: AbortSignal;
    isUseQueryArrays?: boolean;
} & Omit<RequestInit, "method" | "body">;

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type HttpQueryParams = Record<string, string | number | boolean | string[] | number[] | undefined>;

export class HttpClient {
    private readonly baseUrl: string;

    constructor(
        baseUrl: string,
        private readonly onServerUnderConstruction: () => void,
    ) {
        if (baseUrl[baseUrl.length - 1] !== "/") {
            this.baseUrl = baseUrl + "/";
        } else {
            this.baseUrl = baseUrl;
        }
    }

    async get<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "GET", false, request);
    }

    async post<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "POST", false, request);
    }

    async patch<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "PATCH", false, request);
    }

    async put<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "PUT", false, request);
    }

    async delete<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "DELETE", false, request);
    }

    async send<R>(
        url: string,
        method: HttpMethod,
        isDeprecatedUsage: boolean,
        request?: HttpClientRequest,
    ): Promise<R> {
        let headers = request?.headers;
        if (!(request?.body instanceof FormData)) {
            headers = {
                ...headers,
                "Content-Type": "application/json",
            }
        }

        const fullUrl = this.buildUrl(url, request?.params, request?.isUseQueryArrays);
        try {
            const response = await fetch(fullUrl, {
                ...request,
                headers,
                method,
                body: request?.body && !(request.body instanceof FormData) ? JSON.stringify(request.body) : request?.body as FormData,
                signal: request?.signal,
            });

            const json = await response.json();
            if (response.ok) {
                if (isDeprecatedUsage && json.status === "collapsed") {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new ServerError(response.status, json.description);
                }
                return json;
            }

            if (response.status === 502) {
                this.onServerUnderConstruction();
                // noinspection ExceptionCaughtLocallyJS
                throw new ServerError(response.status, null);
            }

            if (typeof json.detail === "string") {
                // noinspection ExceptionCaughtLocallyJS
                throw new ServerError(response.status, json.detail);
            } else {
                // noinspection ExceptionCaughtLocallyJS
                throw new ServerError(response.status, null);
            }
        } catch (e) {
            if (e instanceof ServerError || (e as any).name === "AbortError") {
                throw e;
            }

            const error = e as Error;
            throw new ServerError(0, error.message);
        }
    }

    private buildUrl(path: string, params?: HttpQueryParams, isUseQueryArrays?: boolean): string {
        if (!params) {
            return `${this.baseUrl}${path}`;
        }

        const url = new URL(path, this.baseUrl);
        for (const name in params) {
            const value = params[name];
            if (typeof value !== "undefined") {
                if (isUseQueryArrays && Array.isArray(value)) {
                    for (const item of value) {
                        url.searchParams.append(name, item.toString());
                    }
                } else {
                    url.searchParams.set(name, value.toString());
                }
            }
        }

        return url.toString();
    }
}

export class AuthorizedHttpClient {
    private readonly client: HttpClient;

    constructor(
        baseUrl: string,
        onServerUnderConstruction: () => void,
        private readonly authRepo: AuthRepository,
        private readonly sessionStorage: SessionStore,
        private readonly servicesConfig: ServicesConfig,
    ) {
        this.client = new HttpClient(baseUrl, onServerUnderConstruction);
    }

    async get<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "GET", false, request);
    }

    async post<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "POST", false, request);
    }

    async postV0<R>(body: object, signal?: AbortSignal): Promise<R> {
        const response = await this.send<{ answer: R }>("", "POST", true, {body, signal});
        return response.answer;
    }

    async patch<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "PATCH", false, request);
    }

    async put<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "PUT", false, request);
    }

    async delete<R>(url: string, request?: HttpClientRequest): Promise<R> {
        return this.send(url, "DELETE", false, request);
    }

    async getToken(forceRefresh = false): Promise<string> {
        return navigator.locks.request("token-refresh", async () => {
            const token = await this.sessionStorage.getToken();
            if (token === null) {
                throw new Error("User must be authorized");
            }

            if (forceRefresh || token.accessExpirationDate.getTime() <= Date.now()) {
                return (await this.refreshToken()).accessToken;
            }

            return token.accessToken;
        });
    }

    private async send<R>(
        url: string,
        method: HttpMethod,
        isDeprecatedUsage: boolean,
        request?: HttpClientRequest,
    ): Promise<R> {
        try {
            return await this.client.send(
                url,
                method,
                isDeprecatedUsage,
                await this.addAuthHeaders(request, isDeprecatedUsage),
            );
        } catch (e) {
            if (e instanceof ServerError && e.code === 401) {
                return this.client.send(
                    url,
                    method,
                    isDeprecatedUsage,
                    await this.addAuthHeaders(request, isDeprecatedUsage, true),
                );
            }

            throw e;
        }
    }

    private async addAuthHeaders(
        request: HttpClientRequest | undefined,
        isDeprecatedUsage: boolean = false,
        forceRefresh: boolean = false,
    ): Promise<HttpClientRequest | undefined> {
        const token = await this.getToken(forceRefresh);

        if (isDeprecatedUsage) {
            if (typeof request?.body === "object" && request?.body) {
                return {
                    ...request,
                    body: {
                        ...request.body,
                        token: token,
                    },
                };
            } else {
                return request;
            }
        }

        if (request?.body instanceof FormData) {
            request.body.set("token", token);
        }

        return {
            ...request,
            headers: {
                ...request?.headers,
                "Authorization": `Bearer ${token}`,
            }
        };
    }

    private async refreshToken(): Promise<AuthToken> {
        const oldToken = await this.sessionStorage.getToken();
        try {
            const token = await this.authRepo.refreshToken(oldToken?.refreshToken!!);
            this.sessionStorage.setToken(token);
            return token;
        } catch (e: any) {
            console.error(e);

            if (e instanceof ServerError && e.code === 401) {
                this.sessionStorage.clear();
                if (process.env.NODE_ENV === "production") {
                    window.location.href = this.servicesConfig.landing;
                } else {
                    console.error("Unable to refresh token. Redirecting to landing with error:\n", e);
                }

                throw new RefreshTokenError();
            }

            throw e;
        }
    }
}
