import s from "./index.module.css";
import SvgAssistantError from "../../../common/assets/assistant_error.svg"

export const UnderConstruction = () => {
    return (
        <div className={s.construction}>
            <img className={s.constructionAssistant} src={SvgAssistantError} alt=""/>
            <div className={s.constructionText}>
                <p className={s.constructionTitle}>Идёт обновление</p>
                <p className={s.constructionSubtitle}>Пожалуйста, подождите 5 минут, и мы вернемся!</p>
            </div>
        </div>
    );
};