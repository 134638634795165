import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";
import {TriState} from "../../../../common/components/checkbox";
import {SearchedValueFilter, ValueFilterItem, ValueFilterStore} from "./ValueFilterStore";
import {Pageable} from "../../../../common/types";
import {NewUserRepository} from "../../../../users/repositories";
import {formatName} from "../../../../common/utils";
import {InputBoxItem} from "../../../../common/components/input/box";
import {AsyncJob} from "../../../../common/stores/job";
import {TaskRepository} from "../../../repositories";

interface FilterOptions<T, C> {
    typeKey: string;
    includeKey: string;
    excludeKey: string;
    categories: { key: C, title: string }[];
    mapValue: (value: T) => ValueFilterItem;
    onSearch: (value: string, page: number, category: "any" | C | null, signal: AbortSignal) => Promise<Pageable<T>>;
    onFetchSelected: (selected: string[], signal: AbortSignal) => Promise<T[]>;
}

export class CategoryFilterStore<T, C extends string> implements BaseTaskFilterStore {
    selected: ValueFilterItem[];
    categories: FilterCategoryStore<C>[];
    isLoading: boolean;
    private selectedItems: InputBoxItem<SearchedValueFilter<T>>[];
    readonly isVisible = true;
    private readonly fetchSelectedJob: AsyncJob<typeof CategoryFilterStore.prototype._fetchSelected>;

    constructor(readonly options: FilterOptions<T, C>) {
        this.selected = [];
        this.selectedItems = [];
        this.categories = FilterCategoryStore.from(this, options.categories);
        this.isLoading = false;
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchSelectedJob = new AsyncJob({job: this._fetchSelected});
    }

    get error() {
        return this.fetchSelectedJob.errorMessage;
    }

    get selectedMenuItems(): InputBoxItem<SearchedValueFilter<T>>[] {
        if (this.isLoading) {
            return this.selected.map(item => ({
                id: item.value,
                label: item.title,
                value: null as any as SearchedValueFilter<T>,
            }));
        }

        return this.selectedItems;
    }

    get state() {
        return this.categories.find(c => c.state !== TriState.Unchecked)?.state || TriState.Unchecked;
    }

    get isActive() {
        return this.state !== TriState.Unchecked;
    }

    get selectedCategory(): { key: "any" | C, title: string } | null {
        const selected = this.categories
            .filter(c => c.state !== TriState.Unchecked);
        if (selected.length === 0) {
            return null;
        } else if (selected.length === this.categories.length) {
            return {key: "any", title: "Пользователи"};
        } else {
            return {key: selected[0].key, title: selected[0].title};
        }
    }

    fetchSelected() {
        this.fetchSelectedJob.clearError();
        if (this.isLoading && !this.fetchSelectedJob.isPending) {
            this.fetchSelectedJob.start();
        }
    }

    setSelected(values: InputBoxItem<SearchedValueFilter<T>>[]) {
        this.selectedItems = values;
        this.selected = values.map(v => ({
            title: v.label,
            value: v.id.toString(),
        }));
    }

    async searchItems(search: string, page: number, signal: AbortSignal): Promise<{
        canLoadMore: boolean,
        items: SearchedValueFilter<T>[]
    }> {
        const key = this.selectedCategory?.key || null;
        const result = await this.options.onSearch(search, page, key, signal);
        return {
            canLoadMore: result.maxPage > page + 1,
            items: result.items.map(item => ({
                original: item,
                mapped: this.options.mapValue(item),
            })),
        };
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const selected = builder.findValue(this.options.typeKey);
        if (!selected) {
            return;
        }

        const setCategoriesState = (state: TriState) => {
            if (selected === "any") {
                for (const category of this.categories) {
                    category.setState(state);
                }
            } else {
                for (const category of this.categories) {
                    if (category.key === selected) {
                        category.setState(state);
                        break;
                    }
                }
            }
        };

        if (builder.has(this.options.excludeKey)) {
            setCategoriesState(TriState.Indeterminate);
            const category = this.selectedCategory;
            if (category) {
                this.changeSelected(ValueFilterStore.mapDeserialized(
                    category.title,
                    builder.findMany(this.options.excludeKey) || [],
                ));
            }
        } else {
            setCategoriesState(TriState.Checked);
            const category = this.selectedCategory;
            if (category) {
                this.changeSelected(ValueFilterStore.mapDeserialized(
                    category.title,
                    builder.findMany(this.options.includeKey) || [],
                ));
            }
        }
    }

    apply(builder: SerializedFilterBuilderStore, isVisible: boolean) {
        const category = this.selectedCategory;
        if (!category) {
            return;
        }

        if (!isVisible) {
            builder.upsert(this.options.typeKey, "", category.key);
        }

        const values = this.selected.map(item => ({
            title: `${category.title}: ${item.title}`,
            value: item.value,
        }));

        if (this.state === TriState.Checked) {
            builder.upsertMany(this.options.includeKey, values);
        } else {
            builder.upsertMany(this.options.excludeKey, values);
        }
    }

    reset(): void {
        for (const category of this.categories) {
            category.reset();
        }
        this.changeSelected([]);
    }

    isOwnFilter(key: string) {
        return this.options.includeKey === key || this.options.excludeKey === key;
    }

    onCategoryStateChanged(newState: TriState) {
        if (newState === TriState.Unchecked) {
            this.changeSelected([]);
        } else {
            for (const category of this.categories) {
                if (category.state !== TriState.Unchecked) {
                    category.setState(newState);
                }
            }
        }
    }

    // noinspection DuplicatedCode
    private* _fetchSelected(signal: AbortSignal) {
        try {
            const values: T[] = yield this.options.onFetchSelected(this.selected.map(it => it.value), signal);
            const mapped = values.map(it => {
                const mapped = this.options.mapValue(it);
                return {
                    id: mapped.value,
                    label: mapped.title,
                    value: {
                        mapped,
                        original: it,
                    },
                };
            });

            const order = new Map(this.selected.map(({value}, index) => [value, index]));
            this.selectedItems = mapped.sort((x, y) => {
                return (order.get(x.id) ?? Number.MAX_SAFE_INTEGER) - (order.get(y.id) ?? Number.MAX_SAFE_INTEGER);
            });
        } finally {
            this.isLoading = false;
        }
    }

    // noinspection DuplicatedCode
    private changeSelected(selected: ValueFilterItem[]) {
        if (selected.length === 0) {
            this.selected = [];
            this.selectedItems = [];
            this.isLoading = false;
            return;
        }

        if (this.selected.length !== selected.length) {
            this.selected = selected;
            this.isLoading = true;
        } else {
            const isSelectedChanged = selected.some((it, index) => {
                return it.value !== this.selected[index].value;
            });
            this.selected = selected;
            this.isLoading = isSelectedChanged;
        }
    }
}

class FilterCategoryStore<C> {
    state: TriState;

    private constructor(
        readonly key: C,
        readonly title: string,
        private readonly filter: CategoryFilterStore<any, any>,
    ) {
        this.state = TriState.Unchecked;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    static from<C>(filter: CategoryFilterStore<any, any>, items: FilterOptions<any, C>["categories"]) {
        return items.map(c => new FilterCategoryStore(c.key, c.title, filter));
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
        } else {
            this.state = TriState.Checked;
        }
        this.filter.onCategoryStateChanged(this.state);
    }

    setState(state: TriState) {
        this.state = state;
    }

    reset() {
        this.state = TriState.Unchecked;
    }
}

export class CategoryFilterFactory {
    static createCreatorFilter(
        taskRepo: TaskRepository,
        userRepo: NewUserRepository,
        companyId: number | null,
    ) {
        return new CategoryFilterStore({
            typeKey: "user_creator_type",
            includeKey: "creators",
            excludeKey: "exclude_creators",
            categories: [
                {
                    key: "citizen",
                    title: "Жители"
                },
                {
                    key: "etd_user",
                    title: "Сотрудники"
                },
            ],
            onSearch(search, page, category, signal) {
                return userRepo.findAllListedCreators({
                    search,
                    page,
                    signal,
                    limit: 25,
                    type: category,
                    companyId: companyId,
                });
            },
            onFetchSelected(selected, signal) {
                return taskRepo.findItemsInfo("user", selected, signal);
            },
            mapValue: user => ({
                title: formatName(user.fullName),
                value: user.id.toString(),
            }),
        });
    }
}